<template>
  <div class="show-columns">
    <div class="show-columns__action" @click="fnShowColumns()">
      <span class="icon"><i class="ri-alarm-warning-fill"></i></span>
      {{ nameWarningData }}
      <span class="icon angle"
        ><svg
          class="svg-inline--fa fa-angle-down"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="angle-down"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          :class="{ rotateIcon: isShowColumns }"
        >
          <path
            class=""
            fill="currentColor"
            d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
          ></path></svg
      ></span>
    </div>
    <div class="show-columns__content" v-show="isShowColumns">
      <div
        v-for="item in props.listColumnsWarning"
        :key="item.id"
        class="show-columns__item"
        @click="fnChangeShowColumn(item.label, item.value)"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from "vue";
// import { useRoute } from "vue-router";
// const route = useRoute();
const emit = defineEmits([""]);
const props = defineProps({
  listColumnsWarning: Array,
  countCloseWarningData: Number,
  ucTypeValue: Number,
});

const isShowColumns = ref(false);
const nameWarningData = ref();
const fnShowColumns = () => {
  isShowColumns.value = !isShowColumns.value;
  emit("closeListColumnsName");
  emit("closeListFilter");
};
const fnChangeShowColumn = (nameColumn, valueColumn) => {
  nameWarningData.value = nameColumn;
  isShowColumns.value = false;
  emit("changeWarningData", nameColumn, valueColumn);
};
watch(
  () => props.countCloseWarningData,
  (newCountCloseWarningData) => {
    console.log(newCountCloseWarningData);
    isShowColumns.value = false;
  }
);
// watch(
//   () => props.listColumnsWarning,
//   (newListColumnsWarning) => {
//     console.log("newListColumnsWarning", newListColumnsWarning);
//     nameWarningData.value = newListColumnsWarning[0].label;
//   }
// );
watch(
  () => props.ucTypeValue,
  (newUCType) => {
    if (newUCType == 1) {
      nameWarningData.value = props.listColumnsWarning[0].label;
    } else if (newUCType == 2)
      nameWarningData.value = props.listColumnsWarning[1].label;
    else nameWarningData.value = props.listColumnsWarning[2].label;
    console.log("newUCType", newUCType);
  }
);
onMounted(() => {
  if (props.ucTypeValue == 1) {
    nameWarningData.value = props.listColumnsWarning[0].label;
  } else if (props.ucTypeValue == 2)
    nameWarningData.value = props.listColumnsWarning[1].label;
  else nameWarningData.value = props.listColumnsWarning[2].label;
});
</script>
<style scoped lang="scss">
.show-columns {
  margin-right: 12px;
  cursor: pointer;

  &__action {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    border: thin solid #dbdbdb;
    width: 215px;
    height: 34px;
    border-radius: 4px;
    background-color: #fff;
    color: #4a4a4a;
    &:hover {
      border: thin solid #b5b5b5;
    }
  }
  &__content {
    min-width: 215px;
    background-color: #fff;
    box-shadow: 0 0.5em 1em -0.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
    border-radius: 4px;
    position: absolute;
    z-index: 4;
    top: 40px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px;
    height: 33px;
    cursor: pointer;
    transition: 0.2s;
    font-size: 14px;
    &:hover {
      background-color: #ededed6b;
    }
  }
}
.rotateIcon {
  transform: rotate(180deg);
}
.icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  svg {
    height: 16px;
    width: 18px;
  }
  .fa-angle-down {
    transition: 0.2s;
  }
}
</style>
