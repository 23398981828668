<template>
  <div class="show-columns">
    <div 
      class="show-columns__action" 
      @click="fnShowColumns()"
      v-click-outside="checkOutsideEl"
    >
      {{ totalPerPage }}
      <span class="icon angle"
        ><svg
          class="svg-inline--fa fa-angle-down"
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="angle-down"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
          :class="{ rotateIcon: isShowColumns }"
        >
          <path
            class=""
            fill="currentColor"
            d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
          ></path></svg
      ></span>
    </div>
    <div class="show-columns__content" v-show="isShowColumns" v-click-outside="hidePopup">
      <div
        v-for="item in props.listPagesCount"
        :key="item.id"
        class="show-columns__item"
        @click="fnChangePage(item)"
      >
        <span>{{ item }}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineEmits, defineProps, onMounted, ref, watch } from "vue";

const emit = defineEmits([""]);
const props = defineProps({
  listPagesCount: Array,
  pageSize: Number,
});

const isShowColumns = ref(false);
const totalPerPage = ref("");
const check = ref(false);

const fnShowColumns = () => {
  isShowColumns.value = !isShowColumns.value;
  check.value = false;
};
const fnChangePage = (pageSize) => {
  totalPerPage.value = pageSize || "";
  emit("changePageFromListPagination", pageSize);
  isShowColumns.value = false;
};

const checkOutsideEl = () => {
  check.value = true;
};

const hidePopup = () => {
  if(isShowColumns.value && check.value) isShowColumns.value = false;
};

watch(
  () => props.pageSize,
  (newPageSize) => (totalPerPage.value = newPageSize)
);
onMounted(() => {
  totalPerPage.value = props.pageSize;
});
</script>
<style scoped lang="scss">
.show-columns {
  cursor: pointer;

  &__action {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    border: thin solid #dbdbdb;
    width: 64px;
    height: 34px;
    border-radius: 4px;
    background-color: #fff;
    color: #4a4a4a;
    &:hover {
      border: thin solid #b5b5b5;
    }
  }
  &__content {
    min-width: 60px;
    background-color: #fff;
    border: thin solid #dcdcdc;
    border-radius: 4px;
    position: absolute;
    z-index: 4;
    top: 40px;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 7px;
    height: 33px;
    cursor: pointer;
    transition: 0.2s;
    font-size: 14px;
    &:hover {
      background-color: #ededed6b;
    }
  }
}
.rotateIcon {
  transform: rotate(180deg);
}
.icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  svg {
    height: 16px;
    width: 18px;
  }
  .fa-angle-down {
    transition: 0.2s;
  }
}
</style>
