import LanguageService from '@/service/LanguageService'
let model = {};

// dữ liệu form
model.dataForm = {
  // user: null,
};

model.selectPagination = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];
model.warningData = [
  {
    value: 1,
    label: "Customer Network",
  },
  {
    value: 2,
    label: "Behavior",
  },
  {
    value: "null",
    label: LanguageService?.lang?.t_all ?? "t_all"
  },
];
model.tableRules = {
  lengthMenu: [10, 20, 50, 100],
  allowPaging: true,
  allowSorting: true,
  allowSelect: false,
  showFormSearch: false,
  showUrl: true,
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  uc_type: 1,
  sort: "",
  sort_type: "",
  defaultSort: [undefined, "descending"],
  filters: "",
  filterKey: "",
  filterValue: "",
  dataSearch: {
    value: {
      user: "",
    },
    valid: {},
    operator: {
      user: ":regex_i:",
    },
  },
};

export default model;
